import { animate, state, style, transition, trigger } from '@angular/animations';

export const triggerCollapse = trigger('collapse', [
	state(
		'open',
		style({
			height: '*',
			overflow: 'hidden',
		}),
	),
	state(
		'closed',
		style({
			height: '200px',
			overflow: 'hidden',
		}),
	),
	transition('open => closed', [animate('100ms')]),
	transition('closed => open', [animate('100ms')]),
]);

export const triggerFadeInOut = trigger('fadeInOut', [
	transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in', style({ opacity: 1 }))]),
	transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))]),
]);

export const triggerOpenClose = trigger('openClose', [
	transition(':enter', [
		style({ height: '0px', opacity: 0 }),
		animate('150ms', style({ height: '*', opacity: 1 })),
	]),
	transition(':leave', [animate('150ms', style({ height: '0px', opacity: 0 }))]),
]);
